// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  color: var(--widget-main-color, black)
}

.model-range-root {
  position: relative;
  --widget-main-color: black;
}

`, "",{"version":3,"sources":["webpack://./src/app/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB;AACF;;AAEA;EACE,kBAAkB;EAClB,0BAA0B;AAC5B","sourcesContent":[".widget-wrapper {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  align-items: center;\n  justify-content: center;\n  color: var(--widget-main-color, black)\n}\n\n.model-range-root {\n  position: relative;\n  --widget-main-color: black;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
