import { useMemo } from 'react';
import { useCanvasStore } from '@/domain/canvasStore.tsx';
import { getCurrentCar } from '@/shared/lib/utils.ts';

export const useFullProgress = () => {
    const { events } = useCanvasStore();
    const { frameCount } = events.get();

    const activeSection = useMemo(() => getCurrentCar(), [frameCount]);

    const calculatePercentageToSection = (
        sectionStart: number,
        sectionEnd: number,
    ) => ((frameCount - sectionStart) / (sectionEnd - sectionStart)) * 100;

    return {
        calculatePercentageToSection,
        activeSection,
    };
};
