import { useLayoutEffect, useMemo, useReducer, useRef, useState } from 'react';
import { CarsCanvas } from '@/features/canvas/model/Canvas.ts';
import { useCanvasStore } from '@/domain/canvasStore.tsx';
import {
    INITIAL_ANIMATION_FRAME_DELAY,
    MOBILE_FRAMES_DRAG_SENSITIVITY,
    TABLET_FRAMES_DRAG_SENSITIVITY,
    DESKTOP_FRAMES_DRAG_SENSITIVITY,
    isMobile, isTablet,
} from '@/shared/lib/constants.ts';
import {
    carImagesInfo,
    getCurrentCar,
    runCodeWithDelay,
    getCarSections,
} from '@/shared/lib/utils.ts';

export const useCanvas = () => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [, rerender] = useReducer((v) => v + 1, 0);
    const [canvasInstance, setCanvasInstance] = useState<CarsCanvas | null>(null);
    const { events } = useCanvasStore();
    const {
        setIsTextAnimationStarted,
        setIsDragAvailable,
        setIsDraggingActive,
        setIsMouseDown,
        setFrame,
        setIsViewed,
        setCarInfo,
        get,
    } = events;
    const {
        frameCount,
        isTextAnimationStarted,
        isDraggingActive,
        isMouseDown,
        initialCarIndex,
        carsData,
        isStartAnimationFramesLoaded,
    } = get();
    // Кадр, на котором остановится анимация виджета при инициализации
    const INITIAL_ANIMATION_FRAME_CENTER = getCarSections()[initialCarIndex]?.centerFrame;
    // Кадр, с которого виджет начнет анимацию
    const INITIAL_ANIMATION_FRAME_START = getCarSections()[0]?.startFrame;

    const { inView, isViewed } = events.get();
    const sensitivity = useMemo(() => {
        if (isMobile) return MOBILE_FRAMES_DRAG_SENSITIVITY;
        if (isTablet) return TABLET_FRAMES_DRAG_SENSITIVITY;

        return DESKTOP_FRAMES_DRAG_SENSITIVITY;
    }, [isMobile, isTablet]);

    const setCarData = (index: number) => {
        setCarInfo(carsData[index]);
    };

    const renderCarInfo = () => {
        const totalCars = carsData.length;

        for (let i = 0; i < totalCars; i++) {
            const car = carImagesInfo(i);
            if (frameCount >= car.firstFrame && frameCount <= car.lastFrame && getCurrentCar() === i) {
                setCarData(i);
            }
        }
    };

    const goToCarByIndex = (carIndex: number) => {
        if (!isTextAnimationStarted) {
            return;
        }
        const framesToMove = getCarSections()[carIndex - 1].centerFrame - frameCount;

        if (framesToMove > 0) {
            requestAnimationFrame(() => {
                runCodeWithDelay(
                    () => {
                        canvasInstance!.moveRight();
                        canvasInstance!.drawImage();
                    },
                    INITIAL_ANIMATION_FRAME_DELAY,
                    framesToMove,
                );
            });
        } else {
            requestAnimationFrame(() => {
                runCodeWithDelay(
                    () => {
                          canvasInstance!.moveLeft();
                          canvasInstance!.drawImage();
                    },
                    INITIAL_ANIMATION_FRAME_DELAY,
                    -framesToMove,
                );
            });
        }
    };

    useLayoutEffect(() => {
        if (canvasInstance) {
            window.addEventListener('resize', canvasInstance.handleResize);

            return () => {
                window.removeEventListener('resize', canvasInstance.handleResize);
            };
        }
    }, [canvasInstance]);

    return {
        canvasRef,
        isInitialized,
        setIsInitialized,
        rerender,
        canvasInstance,
        setCanvasInstance,
        setIsViewed,
        setIsTextAnimationStarted,
        setIsDragAvailable,
        setFrame,
        setIsMouseDown,
        isMouseDown,
        INITIAL_ANIMATION_FRAME_CENTER,
        INITIAL_ANIMATION_FRAME_START,
        INITIAL_ANIMATION_FRAME_DELAY,
        renderCarInfo,
        frameCount,
        isTextAnimationStarted,
        isDraggingActive,
        setIsDraggingActive,
        inView,
        isViewed,
        goToCarByIndex,
        sensitivity,
        initialCarIndex,
        isStartAnimationFramesLoaded,
    };
};
