import { injectable } from 'inversify';

import { ConfigServiceType, InitConfig } from '@/domain/ModelRange.types.ts';

// @ts-expect-error
@injectable()
class ConfigService implements ConfigServiceType {
    private preloadedState: { config: InitConfig };

    constructor(initConfig?: InitConfig) {
        if (!initConfig) {
            throw new Error('Не заданы настройки');
        }

        const { element } = initConfig;

        if (!element) {
            throw new Error('Не указан селектор элемента');
        }

        const preloadedState = {
            config: initConfig,
        };
        this.preloadedState = preloadedState;
    }

    get settings() {
        const { config } = this.preloadedState;
        return config;
    }
}

export default ConfigService;
