import { $canvas, CarInfo } from '@/domain/canvasStore.tsx';
import { screenSize } from '@/shared/lib/utils.ts';

declare global {
  const ASSETS_PUBLIC_PATH: string;
}

export const getCarImages = (car: CarInfo) => {
    const carImages = car.images[screenSize]?.images;

    if (!carImages) {
        return [];
    }

    return carImages
        .sort((a, b) => a.order - b.order)
        .map((image) => ASSETS_PUBLIC_PATH + image.path);
};

export function getImages() {
    let images: string[] = [];
    const { carsData } = $canvas.get();

    for (let i = 0; i < carsData.length; i++) {
        const car = carsData[i];
        images = images.concat(getCarImages(car));
    }

    return images;
}
