import { useMemo } from 'react';
import { getCarsImagesInfo } from '@/shared/lib/utils.ts';
import { useCanvasStore } from '@/domain/canvasStore.tsx';
import { useCanvas } from '@/features/canvas/model/useCanvas.tsx';

export const useDrag = () => {
    const { events } = useCanvasStore();
    const { isDragAvailable } = events.get();
    const { frameCount } = useCanvas();
    const { firstFrame } = getCarsImagesInfo();
    const isTouched = useMemo(() => frameCount > firstFrame, [frameCount]);

    return {
        isDragAvailable,
        isTouched,
    };
};
