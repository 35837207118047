import { CarImages, CarInfo, CarLink } from '@/domain/canvasStore.tsx';
import { InitConfig } from '@/domain/ModelRange.types.ts';

type CarResponseData = {
    name?: string;
    id?: string;
    priceMin?: number;
    params: Array<any>;
    links: CarLink[];
    position: number;
}

const defaultMarketingInfoJsonPath = 'https://exeed.ru/data/vehicles_marketing_info/index.json';
const defaultCurrency = '₽';

export const getCars = async (config: Partial<InitConfig>): Promise<CarInfo[]> => {
    const cars: CarInfo[] = [];
    let superscript = 0;

    const imagesJsonPath = `${ASSETS_PUBLIC_PATH}/images.json`;

    const currency = config.currency || defaultCurrency;
    const marketingInfoJsonPath = config.marketingInfoJsonPath || defaultMarketingInfoJsonPath;
    const isModelLinkHidden = config.isModelLinkHidden || false;

    const carsInfo = await fetch(marketingInfoJsonPath);
    const carsImages = await fetch(imagesJsonPath);
    const carsInfoData = await carsInfo.json() as CarResponseData[];
    const carsImagesData = await carsImages.json();

    for (let i = 0; i < carsInfoData.length; i++) {
        const carData = carsInfoData[i];

        const model = carData.name || '';
        const id = carData.id || Math.random().toString();
        const priceText = `от ${carData.priceMin || 0} ${currency}`;
        const params = carData.params || [];
        const positioning = params.filter(item => item.id === 'positioning').map(i => i.value)[0];
        const { links, position } = carData;
        const images = carsImagesData?.find((item: CarImages) => item.modelId === carData.id);

        const car: CarInfo = {
            model,
            id,
            priceText,
            positioning,
            links,
            position,
            isOutOfStock: isModelLinkHidden,
            images,
        };

        cars.push(car);
    }

    const checkIsHasImages = (item: CarInfo) => {
        if (item.images && item.images.desktop) {
            return item.images.desktop.images.length;
        }

        return false;
    };

    const filteredCars = cars
        .filter(checkIsHasImages)
        .sort((a, b) => a.images.desktop.centerFrame - b.images.desktop.centerFrame);

    return filteredCars.map(el => {
        superscript += 1;
        return { ...el, superscript };
    });
};
