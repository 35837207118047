export const Circle = ({
    colour,
    percentage,
}: {
  colour: string;
  percentage?: number;
}) => {
    const r = 11;
    const circ = 2 * Math.PI * r;
    const prcnt = percentage || 0;
    const strokePct = ((100 - prcnt) * circ) / 100; // where stroke will start, e.g. from 15% to 100%.
    return (
        <svg>
            <circle
                r={11}
                cx={12}
                cy={12}
                fill="transparent"
                stroke={strokePct !== circ ? colour : ''} // remove colour as 0% sets full circumference
                strokeWidth={1}
                strokeDasharray={circ}
                strokeDashoffset={percentage ? strokePct : 0}
            />
            <circle cx="12" cy="12" r={3} fill={colour} />
        </svg>
    );
};
