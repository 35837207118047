import { getImages } from '@/features/canvas/model/getImagesByPaths.ts';

export const loadedImages: string[] = [];

export const loadImages = (count: number) => {
    const lastLoadedFrameCount = loadedImages.length;
    const images = getImages();

    for (let i = lastLoadedFrameCount; i < count; i += 1) {
        if (!loadedImages.includes(images[i])) {
            loadedImages.push(images[i]);
        }
    }
};
