import { cleanPercentage } from '@/features/progress/model/cleanPercentage.ts';
import { Circle } from '@/features/progress/ui/Circle.tsx';
import { classNames } from '@/shared/lib/utils.ts';
import './Progress.css';

export const Progress = ({
    percentage = 70,
    colour = '#0E1214',
    isActive = false,
    onClick,
}: {
  colour?: string;
  percentage?: number;
  isActive?: boolean;
  onClick: (circle: number) => void;
}) => {
    const pct = cleanPercentage(percentage);
    return (
        <svg
            /* @ts-expect-error */
            onClick={onClick}
            width={24}
            height={24}
            className={classNames('model-range-progress', { notActive: !isActive }, [])}
        >
            <g transform={`rotate(90 ${'12 12'})`}>
                <Circle colour="#0E1214" />
                {isActive && <Circle colour="rgba(14,18,20,0.2)" percentage={100} />}
                {isActive && <Circle colour={colour} percentage={pct} />}
            </g>
            <g />
        </svg>
    );
};
