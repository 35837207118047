import { Progress } from '@/features/progress';
import './Progress.css';
import { getCarSections } from '@/shared/lib/utils.ts';
import { FullProgressProps } from '../model/types.ts';
import { useFullProgress } from '../model/useFullProgress.ts';

export const FullProgress = ({ handleProgressClick }: FullProgressProps) => {
    const { activeSection, calculatePercentageToSection } = useFullProgress();

    return (
        <div className="model-range-progressWrapper">
            {getCarSections().map((s, i) => (
                <Progress
                    key={s.startFrame}
                    isActive={activeSection === i}
                    percentage={calculatePercentageToSection(
                        s.startFrame,
                        s.endFrame,
                    )}
                    onClick={() => handleProgressClick(i + 1)}
                />
            ))}
        </div>
    );
};
