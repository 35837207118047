import { memo } from 'react';
import DragIcon from '@/shared/assets/drag.svg';
import './Drag.css';
import { classNames } from '@/shared/lib/utils.ts';
import { useDrag } from '../model/useDrag.ts';

export const Drag = memo((props: {goToCarByIndex: (index: number) => void}) => {
    const { isDragAvailable, isTouched } = useDrag();

    if (isTouched) {
        return null;
    }

    return (
        <div
            onClick={() => props.goToCarByIndex(2)}
            className={classNames(
                'model-range-drag',
                { 'model-range-animated-drag': isDragAvailable },
                [],
            )}
        >
            {!isTouched && <DragIcon />}
        </div>
    );
});
