import { $canvas, ScreenSize } from '@/domain/canvasStore.tsx';
import { isMobile, isTablet } from '@/shared/lib/constants.ts';

let timeoutId: NodeJS.Timeout;
export const screenSize: ScreenSize = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop';

export function runCodeWithDelay(
    fn: Function,
    delay: number,
    count: number,
    isNeedClearTimeout?: boolean,
) {
    if (count <= 0) {
        return;
    }

    if (isNeedClearTimeout) {
        clearTimeout(timeoutId);
    }

    fn();

    timeoutId = setTimeout(() => {
        runCodeWithDelay(fn, delay, count - 1);
    }, delay);
}

type Mods = Record<string, boolean | string | undefined>;

export function classNames(
    cls: string,
    mods: Mods = {},
    additional: Array<string | undefined> = [],
): string {
    return [
        cls,
        ...additional.filter(Boolean),
        Object.entries(mods)
            .filter(([_, value]) => Boolean(value))
            .map(([className]) => className)
            .join(' '),
    ].join(' ');
}

export const getCarSections = () => {
    const cars = $canvas.get().carsData;

    return cars.map(car => {
        const carImages = car.images[screenSize]?.images;

        return {
            startFrame: carImages[0]?.order,
            centerFrame: car.images[screenSize]?.centerFrame,
            endFrame: carImages[carImages.length - 1]?.order,
        };
    });
};

export const getCarsImagesInfo = () => ({
    firstFrame: getCarSections()[0]?.centerFrame,
    endFrame: getCarSections()[getCarSections().length - 1]?.endFrame,
});

export const carImagesInfo = (index: number) => {
    const currentCar = getCarSections()[index];

    return {
        firstFrame: currentCar.startFrame,
        lastFrame: currentCar.endFrame,
    };
};

export function calculateFrames(
    centerFrame: number,
): number {
    const currentFrame = $canvas.get().frameCount;

    const carSections = getCarSections();
    const { firstFrame, endFrame } = getCarsImagesInfo();

    if (currentFrame < firstFrame) {
        return carSections[0].centerFrame - currentFrame;
    }

    if (currentFrame > endFrame) {
        return currentFrame - carSections[carSections.length - 1].centerFrame;
    }

    return Math.abs(currentFrame - centerFrame);
}

export const getCarsFrames = (carCount: number) => {
    const { startFrame, centerFrame } = getCarSections()[carCount];
    const endFrame = getCarSections()[carCount + 1]?.startFrame || getCarSections()[carCount]?.endFrame;
    return { startFrame, endFrame, centerFrame };
};

export function getCurrentCar() {
    const frame = $canvas.get().frameCount;
    for (let i = 0; i < getCarSections().length; i++) {
        const section = getCarSections()[i];
        if (frame >= section.startFrame && frame <= section.endFrame) {
            return i;
        }
    }
    return frame < getCarSections()[0]?.endFrame ? 0 : getCarSections().length;
}

export function getNextCarIndex() {
    const currentCarIndex = getCurrentCar();

    if (getCarSections()[currentCarIndex + 1]) {
        return currentCarIndex + 1;
    }
    return currentCarIndex;
}

export function getPrevCarIndex() {
    const currentCarIndex = getCurrentCar();
    if (getCarSections()[currentCarIndex - 1]?.startFrame) {
        return currentCarIndex - 1;
    }
    return currentCarIndex;
}

export function checkIsCenterFrameSimilar(centerFrame: number) {
    const frame = $canvas.get().frameCount;
    return centerFrame === frame;
}

export const roundToNearest = (number: number, step: number) => {
    if (step <= 0) {
        step = 0.1;
    }

    return Math.round(number / step) * step;
};

export const getAboutCarLink = () => {
    const currentCarInfoLinks = $canvas.get().carInfo?.links;
    if (currentCarInfoLinks && currentCarInfoLinks.length > 0) {
        const link = currentCarInfoLinks?.find(link => link.id === 'about');
        return link?.url || '#';
    }

    return '#';
};

let prevX: number | null = null;
let prevY: number | null = null;

export const getMovementX = (event: MouseEvent | TouchEvent): number | undefined => {
    if (!(event instanceof MouseEvent)) {
        let movementX;
        const touch = event.touches[0];
        const { clientX } = touch;
        if (prevX) {
            movementX = clientX - prevX;
        }
        prevX = clientX;
        return movementX;
    }
    if (event instanceof MouseEvent) {
        return event.movementX;
    }
};

export const getMovementY = (event: MouseEvent | TouchEvent): number | undefined => {
    if (!(event instanceof MouseEvent)) {
        let movementY;
        const touch = event.touches[0];
        const { clientY } = touch;
        if (prevY) {
            movementY = clientY - prevY;
        }
        prevY = clientY;
        return movementY;
    }
    if (event instanceof MouseEvent) {
        return event.movementY;
    }
};

type SuperscriptMapType = {
    [key: string]: string;
}

export const getSuperscript = (number: number): string => {
    const digits = String(number);
    const superscriptMap: SuperscriptMapType = {
        0: '⁰',
        1: '¹',
        2: '²',
        3: '³',
        4: '⁴',
        5: '⁵',
        6: '⁶',
        7: '⁷',
        8: '⁸',
        9: '⁹',
    };

    let result = '';
    for (let i = 0; i < digits.length; i++) {
        const digit = digits[i];
        result += superscriptMap[digit];
    }

    return result;
};

export const transliterate = (text?: string) => {
    if (!text) return text;

    text = text.toLowerCase();

    const cyrillicToLatinMap: { [key: string]: string } = {
        а: 'a',
        б: 'b',
        в: 'v',
        г: 'g',
        д: 'd',
        е: 'e',
        ё: 'yo',
        ж: 'zh',
        з: 'z',
        и: 'i',
        й: 'i',
        к: 'k',
        л: 'l',
        м: 'm',
        н: 'n',
        о: 'o',
        п: 'p',
        р: 'r',
        с: 's',
        т: 't',
        у: 'u',
        ф: 'f',
        х: 'kh',
        ц: 'ts',
        ч: 'ch',
        ш: 'sh',
        щ: 'shch',
        ъ: '',
        ы: 'y',
        ь: '',
        э: 'e',
        ю: 'yu',
        я: 'ya',
    };

    return text
        .split('')
        .map(char => cyrillicToLatinMap[char] || char)
        .join('')
        .split(' ')
        .join('-');
};
