import { Container } from 'inversify';

import { InitConfig } from '@/domain/ModelRange.types.ts';

import 'reflect-metadata';

import ConfigService from './ConfigService.ts';
import ServiceTypes from './ServiceTypes.ts';

const injectContainer = new Container();

export const initServices = (initConfig?: InitConfig) => {
    const method = injectContainer.isBound(ServiceTypes.Config)
        ? 'rebind'
        : 'bind';

    injectContainer[method]<InitConfig>(ServiceTypes.Config)
        .toDynamicValue((_) => new ConfigService(initConfig) as unknown as InitConfig)
        .inSingletonScope();
};

export default injectContainer;
