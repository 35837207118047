// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("TacticSans-Bld.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("TacticSans-Bld.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("TacticSans-Bld.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "TacticSans-Bld";
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff"),
  url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2"),
  url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}
`, "",{"version":3,"sources":["webpack://./src/shared/assets/fonts/tacticSans.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB;;4DAE4C;AAC9C","sourcesContent":["@font-face {\n  font-family: \"TacticSans-Bld\";\n  font-display: swap;\n  src: url(\"TacticSans-Bld.woff\") format(\"woff\"),\n  url(\"TacticSans-Bld.woff2\") format(\"woff2\"),\n  url(\"TacticSans-Bld.ttf\") format(\"truetype\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
