// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.model-range-drag {
    position: absolute;
    top: calc(50% - 40px);
    transform: translateY(-50%);
    left: 15%;
    -webkit-user-select: none; /* Chrome, Safari, Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    width: 160px;
    height: 160px;
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.8s linear;

    &:hover {
        opacity: 1;
        animation-name: none;
    }
}

.model-range-animated-drag {
    animation: flickerAnimation 3s infinite;
}

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 1439px) {
    .model-range-drag {
        width: 120px;
        height: 120px;
    }
}

@media all and (max-width: 767px) {
    .model-range-drag {
        display: none;
    }

    .model-range-drag {
        width: 80px;
        height: 80px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/features/drag/ui/Drag.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,2BAA2B;IAC3B,SAAS;IACT,yBAAyB,EAAE,0BAA0B;IACrD,sBAAsB,EAAE,YAAY;IACpC,qBAAqB,EAAE,2BAA2B;IAClD,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,UAAU;IACV,eAAe;IACf,+BAA+B;;IAE/B;QACI,UAAU;QACV,oBAAoB;IACxB;AACJ;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".model-range-drag {\n    position: absolute;\n    top: calc(50% - 40px);\n    transform: translateY(-50%);\n    left: 15%;\n    -webkit-user-select: none; /* Chrome, Safari, Opera */\n    -moz-user-select: none; /* Firefox */\n    -ms-user-select: none; /* Internet Explorer/Edge */\n    user-select: none;\n    width: 160px;\n    height: 160px;\n    opacity: 0;\n    cursor: pointer;\n    transition: opacity 0.8s linear;\n\n    &:hover {\n        opacity: 1;\n        animation-name: none;\n    }\n}\n\n.model-range-animated-drag {\n    animation: flickerAnimation 3s infinite;\n}\n\n@keyframes flickerAnimation {\n    0% {\n        opacity: 1;\n    }\n    50% {\n        opacity: 0;\n    }\n    100% {\n        opacity: 1;\n    }\n}\n\n@media screen and (max-width: 1439px) {\n    .model-range-drag {\n        width: 120px;\n        height: 120px;\n    }\n}\n\n@media all and (max-width: 767px) {\n    .model-range-drag {\n        display: none;\n    }\n\n    .model-range-drag {\n        width: 80px;\n        height: 80px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
