import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { ImagesForLoading } from '@/features/ImagesForLoading';
import { Canvas } from '@/features/canvas';
import { Drag } from '@/features/drag/ui/Drag.tsx';
import { Text } from '@/features/text';
import { BottomText } from '@/features/bottomText';
import './styles/index.css';
import { useCanvasStore } from '@/domain/canvasStore.tsx';
import './App.css';
import { FullProgress } from '@/features/progress/ui/FullProgress.tsx';

function App() {
    const { ref, inView } = useInView({
        threshold: 0.3,
    });
    const { events: { setInView, setIsStartAnimationFramesLoaded } } = useCanvasStore();

    useEffect(() => {
        setInView(inView);
    }, [inView]);

    return (
        <div className="widget-wrapper" ref={ref}>
            <ImagesForLoading
                inView={inView}
                setIsStartAnimationFramesLoaded={setIsStartAnimationFramesLoaded}
            />
            <Canvas
                renderDragIcon={(handleDragIconClick) => <Drag goToCarByIndex={handleDragIconClick} />}
                text={<Text />}
                bottomText={<BottomText />}
                renderProgress={(handleProgressClick) => <FullProgress handleProgressClick={handleProgressClick} />}
            />
        </div>
    );
}

export default App;
