import ReactDOM from 'react-dom/client';
import { getCars } from '../model/getCars.ts';
import { ConfigServiceType, InitConfig } from '@/domain/ModelRange.types.ts';
import InjectContainer, {
    initServices,
} from '@/domain/service/InjectContainer.ts';
import ServiceTypes from '@/domain/service/ServiceTypes.ts';
import App from '@/app/App.tsx';
import { $canvas } from '@/domain/canvasStore.tsx';
import '@/app/App.css';

export class ModelRange {
    private readonly initConfig: InitConfig;

    private configService: ConfigServiceType;

    constructor(initConfig: InitConfig) {
        this.initConfig = initConfig;

        initServices(this.initConfig);
        this.configService = InjectContainer.get(ServiceTypes.Config);
    }

    init = async () => {
        const { element, ...config } = this.configService.settings;
        const carsData = await getCars(config);

        $canvas.setKey('carsData', carsData);
        const rootElement = document.querySelector(element);
        if (!rootElement) {
            throw new Error(`Не найден элемент ${element}`);
        }
        $canvas.setKey('rootElement', rootElement);

        if (config.initialCarIndex) {
            $canvas.setKey('initialCarIndex', config.initialCarIndex);
        }

        const root = ReactDOM.createRoot(rootElement);
        rootElement.classList.add('model-range-root');

        const app = (
            <App />
        );

        root.render(app);
    };
}
