export const MOBILE_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1200;
export const DESKTOP_BREAKPOINT = 1920;

export const DESKTOP_FRAMES_DRAG_SENSITIVITY = 1; // чем меньше значение, тем сильнее отклик карусели на движение мыши
export const TABLET_FRAMES_DRAG_SENSITIVITY = 1.3;
export const MOBILE_FRAMES_DRAG_SENSITIVITY = 2.5;

export const INITIAL_ANIMATION_FRAME_DELAY = 28;

export const isMobile = window.innerWidth < MOBILE_BREAKPOINT;
export const isTablet = window.innerWidth < TABLET_BREAKPOINT;
