import { useCallback } from 'react';
import { $canvas } from '@/domain/canvasStore.tsx';
import { CarsCanvas } from '@/features/canvas/model/Canvas.ts';
import { isMobile, isTablet } from '@/shared/lib/constants.ts';
import {
    calculateFrames,
    checkIsCenterFrameSimilar,
    getCarsFrames,
    getCurrentCar,
    getMovementX,
    getMovementY,
    getNextCarIndex,
    getPrevCarIndex,
    roundToNearest,
    runCodeWithDelay,
} from '@/shared/lib/utils.ts';

let timeoutId: NodeJS.Timeout | null = null;
let nextCarIndex: number = getCurrentCar();
let mousePositionPercent: number = 0;
let isCarAlreadySwiped: boolean = false;
let isFirstTouchEventPassed: boolean = true;

export const useCanvasController = () => {
    const disableVerticalScroll = (isDraggingActive: boolean, movementX: number, movementY: number) => {
        const isVerticalScroll = Math.abs(movementY) < Math.abs(movementX);

        if ((isTablet || isMobile) && isDraggingActive && isVerticalScroll) {
            document.body.style.overflow = 'hidden';
        }
    };

    const enableVerticalScroll = () => {
        if (isTablet || isMobile) {
            document.body.style.overflow = 'auto';
        }
    };

    const handleCanvasInView = useCallback(
        (
            initialAnimationFrameCenter: number,
            initialAnimationFrameStart: number,
            initialAnimationFramesDelay: number,
            canvasInstance: CarsCanvas,
            setIsViewed: (isViewed: boolean) => void,
            setIsTextAnimationStarted: (isTextAnimationStarted: boolean) => void,
            setIsDragAvailable: (isDragAvailable: boolean) => void,
        ): void => {
            const frames = initialAnimationFrameCenter - initialAnimationFrameStart;
            setIsViewed(true);
            requestAnimationFrame(() => {
                runCodeWithDelay(
                    () => {
                        canvasInstance.moveRight();
                        canvasInstance.drawImage();
                    },
                    initialAnimationFramesDelay,
                    frames,
                );
            });
            setTimeout(
                () => {
                    setIsTextAnimationStarted(true);
                },
                initialAnimationFramesDelay * frames + 600,
            );
            setTimeout(
                () => {
                    setIsDragAvailable(true);
                },
                initialAnimationFramesDelay * frames + 1200,
            );
        },
        [$canvas],
    );

    const handleSwipeDirection = useCallback(
        (direction: 'left' | 'right', carIndex: number, canvasInstance: CarsCanvas): void => {
            const scope = {
                left: canvasInstance.moveLeft,
                right: canvasInstance.moveRight,
            };
            requestAnimationFrame(() => {
                scope[direction]();
                canvasInstance.drawImage();
            });

            if (!isCarAlreadySwiped) {
                isCarAlreadySwiped = true;
                nextCarIndex = carIndex;
            }
        },
        [isCarAlreadySwiped, nextCarIndex],
    );

    const handleMouseDown = useCallback(
        (setIsMouseDown: (isMouseDown: boolean) => void, isTextAnimationStarted: boolean): void => {
            if (!isTextAnimationStarted) {
                return;
            }

            nextCarIndex = getCurrentCar();

            setIsMouseDown(true);
        },
        [],
    );

    const handleMouseUp = useCallback(
        (
            isTextAnimationStarted: boolean,
            setIsMouseDown: (isMouseDown: boolean) => void,
            canvasInstance: CarsCanvas,
            initialAnimationFramesDelay: number,
        ): void => {
            if (!isTextAnimationStarted) {
                return;
            }

            enableVerticalScroll();

            isFirstTouchEventPassed = true;
            isCarAlreadySwiped = false;
            setIsMouseDown(false);

            const { centerFrame } = getCarsFrames(nextCarIndex);
            const count = calculateFrames(centerFrame);

            if (checkIsCenterFrameSimilar(centerFrame)) {
                return;
            }

            requestAnimationFrame(() => {
                runCodeWithDelay(
                    () => {
                        if (!$canvas.get().isMouseDown) {
                            canvasInstance.moveTo(centerFrame);
                            canvasInstance.drawImage();
                        }
                    },
                    initialAnimationFramesDelay,
                    count,
                    true,
                );
            });
        },
        [checkIsCenterFrameSimilar, calculateFrames, getCarsFrames],
    );

    const handleMouseLeave = useCallback(
        (
            setIsMouseDown: (isMouseDown: boolean) => void,
            canvasInstance: CarsCanvas,
            initialAnimationFramesDelay: number,
            isTextAnimationStarted: boolean,
        ): void => {
            setIsMouseDown(false);
            enableVerticalScroll();

            if (!isTextAnimationStarted) {
                return;
            }

            const currentCarIndex = getCurrentCar();
            const { centerFrame } = getCarsFrames(currentCarIndex);
            const count = calculateFrames(centerFrame);

            if (checkIsCenterFrameSimilar(centerFrame)) {
                return;
            }

            requestAnimationFrame(() => {
                runCodeWithDelay(
                    () => {
                        canvasInstance.moveTo(centerFrame);
                        canvasInstance.drawImage();
                    },
                    initialAnimationFramesDelay,
                    count,
                    true,
                );
            });
        },
        [calculateFrames, getCurrentCar, getCarsFrames, checkIsCenterFrameSimilar, requestAnimationFrame],
    );

    const handleMouseDrag = useCallback(
        (setIsDraggingActive: (isDraggingActive: boolean) => void): void => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            setIsDraggingActive(true);

            timeoutId = setTimeout(() => {
                setIsDraggingActive(false);
            }, 100);
        },
        [timeoutId],
    );

    const checkIsMouseSwiped = useCallback(
        (movementX: number, canvasInstance: CarsCanvas) => {
            const movementSwipeSpeed = isTablet ? 5 : 10;
            const isWithinThreshold = Math.abs(movementX) > movementSwipeSpeed;
            const nextCarIndex = getNextCarIndex();
            const prevCarIndex = getPrevCarIndex();

            // фикс дерганий в противоположную сторону при первом срабатывании touch ивента
            if (movementX && isTablet && isFirstTouchEventPassed) {
                const isWithinThreshold = Math.abs(movementX) > 15;
                isFirstTouchEventPassed = false;
                if (isWithinThreshold) {
                    return;
                }
            }

            // ленивый свайп
            if (isWithinThreshold && movementX) {
                if (movementX < 0 && nextCarIndex) {
                    handleSwipeDirection('right', nextCarIndex, canvasInstance);
                }

                if (movementX > 0 && prevCarIndex >= 0) {
                    handleSwipeDirection('left', prevCarIndex, canvasInstance);
                }

                return true;
            }
        },
        [nextCarIndex, isFirstTouchEventPassed, getNextCarIndex, getPrevCarIndex],
    );

    const handleMouseMove = useCallback(
        (
            event: MouseEvent | TouchEvent,
            sensitivity: number,
            canvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
            isTextAnimationStarted: boolean,
            rerender: () => void,
            setIsDraggingActive: (isDraggingActive: boolean) => void,
            canvasInstance: CarsCanvas,
        ): void => {
            const clientX = !(event instanceof MouseEvent) ? event.touches[0].clientX : event.clientX;
            const movementX = getMovementX(event);
            const currentMousePositionPercent = roundToNearest((clientX / window.innerWidth) * 100, sensitivity);
            const movementY = getMovementY(event);
            const { isMouseDown, isDraggingActive } = $canvas.get();

            if (typeof movementY === 'number' && typeof movementX === 'number' && isMouseDown) {
                disableVerticalScroll(isDraggingActive, movementX, movementY);
            }

            if (!isTextAnimationStarted) {
                return;
            }

            if (!canvasRef.current) {
                rerender();
                return;
            }

            if (isMouseDown) {
                handleMouseDrag(setIsDraggingActive);
            } else {
                requestAnimationFrame(canvasInstance.drawImage);
                return;
            }

            const x = clientX - canvasRef.current.offsetLeft;

            if (!(x > 0 && x < canvasRef.current.width)) {
                return;
            }

            if (movementX) {
                const isSwiped = checkIsMouseSwiped(movementX, canvasInstance);
                if (isSwiped) return;
            }

            if (movementX && currentMousePositionPercent !== mousePositionPercent) {
                mousePositionPercent = currentMousePositionPercent;

                if (movementX && movementX > 0) {
                    requestAnimationFrame(() => {
                        canvasInstance.moveLeft();
                        canvasInstance.drawImage();
                    });
                }
                if (movementX && movementX < 0) {
                    requestAnimationFrame(() => {
                        canvasInstance.moveRight();
                        canvasInstance.drawImage();
                    });
                }

                if (nextCarIndex !== getCurrentCar()) {
                    nextCarIndex = getCurrentCar();
                }
            }
        },
        [
            nextCarIndex,
            mousePositionPercent,
            isFirstTouchEventPassed,
            getMovementX,
            getCurrentCar,
            getNextCarIndex,
            getPrevCarIndex,
            handleMouseDrag,
            roundToNearest,
            checkIsMouseSwiped,
        ],
    );

    return {
        handleCanvasInView,
        handleMouseDown,
        handleMouseUp,
        handleMouseLeave,
        handleMouseDrag,
        handleMouseMove,
    };
};
