// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notActive {
  opacity: 0.2;
}

.model-range-progress {
  cursor: pointer;
}

.model-range-progressWrapper {
  display: flex;
  gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/features/progress/ui/Progress.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,SAAS;AACX","sourcesContent":[".notActive {\n  opacity: 0.2;\n}\n\n.model-range-progress {\n  cursor: pointer;\n}\n\n.model-range-progressWrapper {\n  display: flex;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
