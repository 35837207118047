import { memo } from 'react';
import './BottomText.css';
import Arrow from '@/shared/assets/arrow.svg';
import { useCanvasStore } from '@/domain/canvasStore.tsx';
import { classNames, transliterate } from '@/shared/lib/utils.ts';

export const BottomText = memo(() => {
    const { events } = useCanvasStore();
    const { isTextAnimationStarted, carInfo } = events.get();
    return (
        <div
            className={classNames(
                'model-range-bottom-text',
                { 'model-range-animated-text': isTextAnimationStarted },
                [],
            )}
        >
            {carInfo && carInfo.links?.length > 0 && !carInfo.isOutOfStock && (
                <div className="model-range-container">
                    <a
                        target="_blank"
                        href={`/cars/#/?model=${transliterate(carInfo?.model)}`}
                        className={classNames('model-range-bottom-text-main', {}, [carInfo?.links?.[0].design.style])}
                        rel="noreferrer"
                    >
                        В наличии
                        <Arrow />
                    </a>
                </div>
            )}
            {
                carInfo && carInfo.links?.length > 0 && carInfo.links.map(link => (
                    <div key={link.id} className="model-range-container">
                        <a
                            target="_blank"
                            href={link.url}
                            className={classNames('model-range-bottom-text-main', {}, [link.design.style])}
                            rel="noreferrer"
                        >
                            О модели
                            <Arrow />
                        </a>
                    </div>
                ))
            }
        </div>
    );
});
