export function formatNumber(stringNumber: string): string {
    const number = stringNumber.match(/\d+/);

    if (number) {
        const formattedNumber = Number(number[0]).toLocaleString('ru-RU');

        return stringNumber.replace(number[0], formattedNumber);
    }

    return stringNumber;
}
