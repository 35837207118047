import { map } from 'nanostores';
import { useStore } from '@nanostores/react';

export type ScreenSize = 'desktop' | 'tablet' | 'mobile';

export type CarLink = {
  action: string;
  id: string;
  design: {
    layout: string;
    style: string
  };
  url: string;
  title: string;
}

export type CarImages = {
  [key in ScreenSize]: {
      centerFrame: number;
      images: {path: string; order: number;}[];
  }
} & { modelId: string; }

export type CarInfo = {
  id: string;
  model: string;
  positioning?: string;
  priceText?: string;
  isOutOfStock?: boolean;
  links: CarLink[];
  position: number;
  superscript?: number | null;
  images: CarImages;
};

export type CanvasStore = {
  frameCount: number;
  isStartAnimationFramesLoaded: boolean;
  carInfo: CarInfo | null;
  carsData: CarInfo[];
  initialCarIndex: number;
  isTextAnimationStarted: boolean;
  isDragAvailable: boolean;
  isDraggingActive: boolean;
  isMouseDown: boolean;
  inView: boolean;
  isViewed: boolean;
  rootElement: Element | null;
};

export const $canvas = map<CanvasStore>({
    frameCount: 0,
    initialCarIndex: 0,
    isStartAnimationFramesLoaded: false,
    carsData: [],
    carInfo: null,
    isTextAnimationStarted: false,
    isDragAvailable: false,
    isDraggingActive: false,
    isMouseDown: false,
    inView: false,
    isViewed: false,
    rootElement: null,
});

export const useCanvasStore = () => {
    const canvasStore = useStore($canvas);

    const events = {
        get: () => canvasStore,
        setCarInfo: (value: CarInfo) => $canvas.setKey('carInfo', value),
        setFrame: (value: number) => $canvas.setKey('frameCount', value),
        setIsStartAnimationFramesLoaded: (value: boolean) => $canvas.setKey('isStartAnimationFramesLoaded', value),
        setIsTextAnimationStarted: (value: boolean) => $canvas.setKey('isTextAnimationStarted', value),
        setIsDragAvailable: (value: boolean) => $canvas.setKey('isDragAvailable', value),
        setIsDraggingActive: (value: boolean) => $canvas.setKey('isDraggingActive', value),
        setIsMouseDown: (value: boolean) => $canvas.setKey('isMouseDown', value),
        setInView: (value: boolean) => $canvas.setKey('inView', value),
        setIsViewed: (value: boolean) => $canvas.setKey('isViewed', value),
    };

    return {
        canvasStore: $canvas,
        events,
    };
};
