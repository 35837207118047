import { useCallback, useEffect, useState } from 'react';
import { $canvas } from '@/domain/canvasStore.tsx';
import {
    getCarSections,
    getCurrentCar,
} from '@/shared/lib/utils.ts';
import { loadImages, loadedImages } from '../model';

type ImagesForLoadingPropsType = {
    inView: boolean;
    setIsStartAnimationFramesLoaded: (isStartAnimationFramesLoaded: boolean) => void;
}
export const ImagesForLoading = ({ inView, setIsStartAnimationFramesLoaded }: ImagesForLoadingPropsType) => {
    const currentCar = getCurrentCar();
    const [ loadedFrameCount, setLoadedFrameCount ] = useState(0);
    const [ framesInStack, setFramesInStack ] = useState(0);
    const { initialCarIndex } = $canvas.get();

    const onLoadImageHandler = useCallback(() => {
        setLoadedFrameCount((prevState) => prevState + 1);
    }, []);

    useEffect(() => {
        const isStartAnimationFramesLoaded = loadedFrameCount >= getCarSections()[initialCarIndex].centerFrame - 1;

        if (isStartAnimationFramesLoaded) {
            setIsStartAnimationFramesLoaded(true);
        }
    }, [loadedFrameCount]);

    useEffect(() => {
        const carSections = getCarSections();
        const { centerFrame } = getCarSections()[initialCarIndex];
        const oneNextCar = carSections[currentCar + 1]?.centerFrame;
        const twoNextCars = carSections[currentCar + 2]?.centerFrame;

        const nextLoad = twoNextCars || oneNextCar;

        if (!framesInStack) {
            setFramesInStack(centerFrame);
            loadImages(centerFrame);
        }

        if (inView && framesInStack < nextLoad) {
            setFramesInStack(nextLoad);
            loadImages(nextLoad);
        }
    }, [currentCar, framesInStack, inView, getCarSections]);

    return (
        <>
            {loadedImages.map((image) => (
                <img
                    key={image}
                    src={image}
                    onLoad={onLoadImageHandler}
                    style={{ display: 'none' }}
                    alt="for loading"
                />
            ))}
        </>
    );
};
