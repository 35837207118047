import { memo, useEffect, useMemo } from 'react';
import './Text.css';
import { classNames, getAboutCarLink, getSuperscript } from '@/shared/lib/utils.ts';
import { formatNumber } from '@/features/text/model/formatNumber.ts';
import { useText } from '@/features/text/model/useText.ts';

export const Text = memo(() => {
    const {
        isTextAnimationStarted,
        setIsTextVisible,
        isTextVisible,
        setVisibleCar,
        visibleCarDebounce,
        isDraggingActiveDebounce,
        carInfo,
    } = useText();

    const modelRangeTextLink = useMemo(() => {
        return getAboutCarLink();
    }, [carInfo]);

    const priceText = useMemo(() => {
        const formattedNumber = formatNumber(carInfo?.priceText || '0');

        if (carInfo?.superscript) {
            const carSuperscript = getSuperscript(carInfo?.superscript);
            return formattedNumber + carSuperscript;
        }

        return formattedNumber;
    }, [carInfo])

    useEffect(() => {
        setIsTextVisible(false);
        setVisibleCar(carInfo);
    }, [carInfo]);

    useEffect(() => {
        if (!isTextAnimationStarted) {
            return;
        }
        setIsTextVisible(true);
    }, [visibleCarDebounce, isTextAnimationStarted, isDraggingActiveDebounce]);

    const renderText = useMemo(() => (
        <a
            href={modelRangeTextLink}
            target={'_blank'}
            className={classNames('model-range-text model-range-animated-fade-in')}
        >
            <p className="model-range-main">{carInfo?.positioning}</p>
            <p className="model-range-model">{carInfo?.model}</p>
            <p className="model-range-price">{priceText}</p>
        </a>
    ), [isTextVisible]);

    return isTextVisible ? renderText : null;
});
