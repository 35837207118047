// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/cjs.js!../../shared/assets/fonts/tacticSans.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root {
    height: calc(100vh - 80px);
    min-height: calc(100vh - 80px);
    background-color: white;
}

@media all and (orientation: landscape) {
    @media all and (max-height: 640px) {
        #root {
            height: 100vh;
            min-height: 100vh;
        }
    }
}

/*Отнимаем от 100vh высоту заголовка на сайте перкса*/
@media all and (orientation: portrait) {
    #root {
        min-height: calc(100vh - 267px);
        height: calc(100vh - 267px);
    }

    @media all and (max-width: 1449px) {
        #root {
            min-height: calc(100vh - 208px);
            height: calc(100vh - 208px);
        }
    }

    @media all and (max-width: 1023px) {
        #root {
            min-height: calc(100vh - 184px);
            height: calc(100vh - 184px);
        }
    }

    @media all and (max-width: 767px) {
        #root {
            min-height: calc(100vh - 124px);
            height: calc(100vh - 124px);
        }
    }

    @media all and (max-height: 580px) {
        #root {
            min-height: calc(100vh - 80px);
            height: calc(100vh - 80px);
        }
    }
}
`, "",{"version":3,"sources":["webpack://./src/app/styles/index.css"],"names":[],"mappings":"AAEA;IACI,0BAA0B;IAC1B,8BAA8B;IAC9B,uBAAuB;AAC3B;;AAEA;IACI;QACI;YACI,aAAa;YACb,iBAAiB;QACrB;IACJ;AACJ;;AAEA,qDAAqD;AACrD;IACI;QACI,+BAA+B;QAC/B,2BAA2B;IAC/B;;IAEA;QACI;YACI,+BAA+B;YAC/B,2BAA2B;QAC/B;IACJ;;IAEA;QACI;YACI,+BAA+B;YAC/B,2BAA2B;QAC/B;IACJ;;IAEA;QACI;YACI,+BAA+B;YAC/B,2BAA2B;QAC/B;IACJ;;IAEA;QACI;YACI,8BAA8B;YAC9B,0BAA0B;QAC9B;IACJ;AACJ","sourcesContent":["@import '@/shared/assets/fonts/tacticSans.css';\n\n#root {\n    height: calc(100vh - 80px);\n    min-height: calc(100vh - 80px);\n    background-color: white;\n}\n\n@media all and (orientation: landscape) {\n    @media all and (max-height: 640px) {\n        #root {\n            height: 100vh;\n            min-height: 100vh;\n        }\n    }\n}\n\n/*Отнимаем от 100vh высоту заголовка на сайте перкса*/\n@media all and (orientation: portrait) {\n    #root {\n        min-height: calc(100vh - 267px);\n        height: calc(100vh - 267px);\n    }\n\n    @media all and (max-width: 1449px) {\n        #root {\n            min-height: calc(100vh - 208px);\n            height: calc(100vh - 208px);\n        }\n    }\n\n    @media all and (max-width: 1023px) {\n        #root {\n            min-height: calc(100vh - 184px);\n            height: calc(100vh - 184px);\n        }\n    }\n\n    @media all and (max-width: 767px) {\n        #root {\n            min-height: calc(100vh - 124px);\n            height: calc(100vh - 124px);\n        }\n    }\n\n    @media all and (max-height: 580px) {\n        #root {\n            min-height: calc(100vh - 80px);\n            height: calc(100vh - 80px);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
