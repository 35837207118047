export const listeners = (
    el: HTMLElement,
    mouseDownCallback: () => void,
    mouseUpCallback: () => void,
    mouseLeaveCallback: () => void,
    mouseMoveCallback: (event: MouseEvent | TouchEvent) => void,
) => {
    el.addEventListener('mouseleave', mouseLeaveCallback);
    el.addEventListener('mousedown', mouseDownCallback);
    el.addEventListener('mouseup', mouseUpCallback);
    el.addEventListener('mousemove', mouseMoveCallback);
    el.addEventListener('touchstart', mouseDownCallback);
    el.addEventListener('touchend', mouseUpCallback);
    el.addEventListener('touchmove', mouseMoveCallback);

    return () => {
        el.removeEventListener('mouseleave', mouseLeaveCallback);
        el.removeEventListener('mousedown', mouseDownCallback);
        el.removeEventListener('mouseup', mouseUpCallback);
        el.removeEventListener('mousemove', mouseMoveCallback);
        el.removeEventListener('touchstart', mouseDownCallback);
        el.removeEventListener('touchend', mouseUpCallback);
        el.removeEventListener('touchmove', mouseMoveCallback);
    };
};
