import { getImages } from '@/features/canvas/model/getImagesByPaths.ts';
import { getCarSections, screenSize } from '@/shared/lib/utils.ts';

export type CanvasProps = {
  canvas: HTMLCanvasElement;
  setFrame: (frame: number) => void;
  initialFrameCount: number;
};

export interface CarsCanvasImpl {
  getImage: () => HTMLImageElement;
  clearReact: () => void;
  drawImage: () => void;
  initCanvasWithAnimation: () => Promise<void>;
  increaseFrameCount: () => void;
  decreaseFrameCount: () => void;
  moveTo: (frame: number) => void;
  moveRight: () => void;
  moveLeft: () => void;
}

let timeoutId: NodeJS.Timeout;

const delay = (milliseconds: number) => new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
});

export class CarsCanvas implements CarsCanvasImpl {
    frameCount: number;

    ctx: CanvasRenderingContext2D;

    images: string[];

    canvasWidth: number;

    canvasHeight: number;

    imagesLength: number;

    rect: DOMRect;

    canvas: HTMLCanvasElement;

    canvasNode: HTMLElement | null;

    setFrame: (frame: number) => void;

    constructor({ canvas, setFrame, initialFrameCount }: CanvasProps) {
        this.canvas = canvas;
        this.ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        this.rect = canvas.getBoundingClientRect();
        this.frameCount = initialFrameCount;
        this.canvasWidth = canvas.parentElement?.clientWidth || window.innerWidth;
        this.canvasHeight = canvas.parentElement?.clientHeight || window.innerHeight;
        this.images = getImages();
        this.imagesLength = this.images.length;
        this.canvasNode = document.getElementById('widget');
        this.setFrame = setFrame;
    }

    handleResize = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            this.canvasWidth = this.canvas.parentElement?.clientWidth || window.innerWidth;
            this.canvasHeight = this.canvas.parentElement?.clientHeight || window.innerHeight;

            this.canvas.width = this.canvasWidth;
            this.canvas.height = this.canvasHeight;

            this.drawImage();
        }, 50);
    };

    getImage = () => {
        const img = new Image();
        img.src = this.images[this.frameCount - 1];

        return img;
    };

    clearReact = () => {
        this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    };

    drawImage = () => {
        this.ctx.imageSmoothingQuality = 'low';
        const img = this.getImage();

        img.onload = () => {
            const imageWidth = img.width;
            const imageHeight = img.height;
            const topPadding = screenSize === 'tablet' ? 40 : 60;

            const scaleX = this.canvasWidth / imageWidth;

            const scaledWidth = imageWidth * scaleX;
            const scaledHeight = imageHeight * scaleX;

            const x = (this.canvasWidth - scaledWidth) / 2;
            const y = ((this.canvasHeight - scaledHeight) / 2) - topPadding;

            this.ctx.drawImage(
                img,
                x,
                y,
                scaledWidth,
                scaledHeight,
            );
        };
    };

    initCanvasWithAnimation = async () => {
        await delay(1000);
        this.canvasNode?.classList.remove('model-range-fade-in');
    };

    increaseFrameCount = () => {
        const { endFrame } = getCarSections()[getCarSections().length - 1];

        if (this.frameCount + 1 > endFrame) {
            return;
        }
        this.frameCount += 1;
        this.setFrame(this.frameCount);
    };

    decreaseFrameCount = () => {
        const { centerFrame } = getCarSections()[0];

        if (this.frameCount - 1 < centerFrame) {
            return;
        }
        this.frameCount -= 1;
        if (this.frameCount === 0) {
            this.frameCount = 1;
        }
        this.setFrame(this.frameCount);
    };

    moveTo = (frame: number) => {
        if (frame === this.frameCount) {
            return;
        }

        if (frame > this.frameCount) {
            this.increaseFrameCount();
        } else {
            this.decreaseFrameCount();
        }
    };

    moveRight = () => {
        this.increaseFrameCount();
    };

    moveLeft = () => {
        this.decreaseFrameCount();
    };
}
