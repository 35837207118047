import { useState } from 'react';
import { CarInfo, useCanvasStore } from '@/domain/canvasStore.tsx';
import useDebounce from '@/shared/lib/hooks/useDebounce.ts';

export const useText = () => {
    const { events } = useCanvasStore();
    const { carInfo, isTextAnimationStarted, isDraggingActive } = events.get();
    const [isTextVisible, setIsTextVisible] = useState(false);
    const [visibleCar, setVisibleCar] = useState<CarInfo | null>(null);

    const visibleCarDebounce = useDebounce(visibleCar, 200);
    const isDraggingActiveDebounce = useDebounce(isDraggingActive, 50);

    return {
        carInfo,
        isTextAnimationStarted,
        isTextVisible,
        setIsTextVisible,
        setVisibleCar,
        visibleCarDebounce,
        isDraggingActiveDebounce,
    };
};
